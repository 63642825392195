import axios from 'axios';

export const getCarlineCatalogYears = async ({ commit }, { makelist, companycode }) => {
	let { data: years, error } = await axios.get('/service/carlineCatalogYearsRequest', {
		params: {
			makelist,
			companycode
		}
	});

	if (error) {
		commit('carlineYears', []);
		throw new Error(error);
	}

	commit('carlineState', {
		makes: [],
		models: [],
		trims: [],
		options: [] });
	commit('carlineYears', years);
};

export const getCarlineCatalogMakes = async ({ commit }, { year, makelist, companycode }) => {
	let { data: makes, error } = await axios.get('/service/carlineCatalogMakesRequest', {
		params: {
			year,
			makelist,
			companycode
		}
	});


	if (error) {
		commit('carlineMakes', []);
		throw new Error(error);
	}

	if (!year) {
		return commit('tireSizeMakes', makes);
	}

	commit('carlineState', {
		models: [],
		trims: [],
		options: [] });

	return commit('carlineMakes', makes);

};

export const getCarlineCatalogModels = async ({ commit }, { year, make, companycode }) => {
	let { data: models, error } = await axios.get('/service/carlineCatalogModelsRequest', {
		params: {
			year,
			make,
			companycode
		}
	});

	if (error) {
		commit('carlineModels', []);
		throw new Error(error);
	}
	commit('carlineState', {
		trims: [],
		options: []
	});
	commit('carlineModels', models);
};

export const getCarlineCatalogTrims = async ({ commit }, { year, make, model, companycode }) => {
	let { data: trims, error } = await axios.get('/service/carlineCatalogTrimsRequest', {
		params: {
			year,
			make,
			model,
			companycode
		}
	});

	if (error) {
		commit('carlineTrims', []);
		throw new Error(error);
	}
	commit('carlineState', { options: [] });
	commit('carlineTrims', trims);
};

export const getCarlineCatalogOptions = async ({ commit }, { year, make, model, trim, companycode }) => {
	let { data: options, error } = await axios.get('/service/carlineCatalogOptionsRequest', {
		params: {
			year,
			make,
			model,
			trim,
			companycode
		}
	});

	if (error) {
		commit('carlineOptions', []);
		throw new Error(error);
	}
	commit('carlineOptions', options);


};

export const getTireSizeCatalog = async ({ commit }, { languageCode, languageLocale, programCode }) => {
	let { data: tireSizes, error } = await axios.get('/service/tireSizeCatalogRequest', {
		params: {
			languageCode,
			languageLocale,
			programCode
		}
	});

	if (error) {
		commit('tireSizes', []);
		throw new Error(error);
	}

	commit('tireSizes', tireSizes);
};
