<template>
    <MountingPortal mountTo="#dealerHeader">
        <h2 class="header-dealer-name" v-html="dealershipnameHTML" role="none"></h2>
    </MountingPortal>
</template>

<script>
import { mapState } from 'vuex';
import themeFinder from '../../plugins/theme-finder';

export default {
	name: 'DealerHeader',
	computed: {
		...mapState('dealer', ['selected']),

		showDealerName () {
			const { defaultDealer } = themeFinder(window.location.hostname);

			if (this.selected.websiteUID) {

				return defaultDealer !== this.selected.websiteUID;
			}

			return false;

		},

		// display dealership name in header
		dealershipnameHTML() {

			if (this.showDealerName) {
				const dn = this.selected.dealershipname;

				// this is specific to Mercedes-Benz.
				// If you need to add another OEM, start the conversation about how
				// dealerName is being exported from JDE.
				// Also, check with all other teams and their usage of DealershipName.

				return dn.includes('MERCEDES-BENZ') ? dn.toLowerCase().replace('mercedes-benz of', '<span>mercedes-benz</span> <span class="lowercase">of</span>') : dn;
			}

			return '';
		}
	}
};
</script>
