import Decimal from 'decimal.js';


export const addCurrency = ( x, y) => {
	let decimal_x = new Decimal(x);
	let decimal_y = new Decimal(y);

	return Number(decimal_x.plus(decimal_y).toFixed(2));
};

export const multiplyCurrency = ( x, y) => {
	let decimal_x = new Decimal(x);
	let decimal_y = new Decimal(y);

	return Number(decimal_x.times(decimal_y).toFixed(2));
};
export const equalsCurrency = ( x, y) => {
	let decimal_x = new Decimal(x);
	let decimal_y = new Decimal(y);

	return decimal_x.equals(decimal_y);
};
