<template>
    <MountingPortal mountTo="#language">
        <div>
            <div class="locale-switcher">
                <label :style="{ color: darkMode ? 'white' : inherit }">{{$t(`message.selectLanguage`)}}</label>
                <select v-model="$i18n.locale" @change="saveUserLanguage" class="" name="Select language">
                    <option v-for="(locale, name) in $i18n.messages" :key="`${name}`" :value="name">
                        {{ locale.language }}
                    </option>
                </select>
            </div>
        </div>
    </MountingPortal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Axios from 'axios';

export default {
	name: 'LocaleSwitcher',
	props: {
		darkMode: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			country: 'usa'
		};
	},
	computed: {
		...mapState('dealer', ['selected'])
	},
	methods: {
		...mapMutations('consumerInfo', ['setUserLanguage']),
		async saveUserLanguage() {
			window.localStorage.setItem('userLocale', this.$i18n.locale);
			await Axios.put('/service/language', { languageCode: this.$i18n.locale });
			this.setUserLanguage(this.$i18n.locale);
			location.reload();
		}
	},
	async created() {
		const storedLocale = window.localStorage.getItem('userLocale');

		if (storedLocale) {
			this.$i18n.locale = storedLocale;
			await Axios.put('/service/language', { languageCode: this.$i18n.locale });
			this.setUserLanguage(this.$i18n.locale);
		}

		this.country = this.selected.country;
	}
};
</script>
