<template>
    <article class="product-item tile">
        <div class="row">
            <!-- Tire Image -->
            <div class="col-2 wpk-tile__img">
                <div class="wpk-tile__tire-img-wrapper">
                    <img
                        :class="imageLoaded ? 'wpk-tile__tire-img' : 'wpk-tile__default-img'"
                        :src="productImg"
                        :alt="wheelPackage.catalogEntryDescription"
                        @error="hideImageButton"
                    />
                </div>
            </div>

            <!-- Tire Info -->
            <div class="col-5 tile__info">
                <div class="row tile__info-wrapper">
                    <!-- Branding -->
                    <div class="col-12">
                        <h5 class="tile__tire-brand">{{ wheelPackage.brand }}</h5>
                        <a
                            class="tile__tire-name"
                        >
                            <!-- <a
                                        class="tile__tire-name"
                                        :href="detailsURL"
                                        @click.prevent="goToPackageDetail"
										> -->
                            {{ wheelPackage.catalogEntryDescription }}
                        </a>
                        <p>Audi Approved Package</p>
                    </div>

                    <!-- Product Attribute Badges -->
                    <ul v-if="hasFlags" class="col-md-12 tile__badges">
                        <li
                            v-if="wheelPackage.localBestSeller"
                            class="tile__local-best-seller"
                        >
                            {{ $t("message.localBestSeller") }}
                        </li>
                        <li
                            v-if="wheelPackage.factoryInstalled"
                            class="tile__original-equipment"
                        >
                            {{ $t("message.originalEquipment") }}
                        </li>
                        <li
                            v-if="wheelPackage.oemTireMarkDescription"
                            class="tile__oem-tiremark"
                        >
                            {{ wheelPackage.oemTireMarkDescription }}
                        </li>
                    </ul>

                    <!-- Specs -->
                    <div class="col-md-12 tile__specs">
                        <ul class="tile__specs-list">
                            <li
                                class="tile__specs-item"
                                v-for="spec in specs"
                                :key="spec"
                            >
                                {{ spec }}
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

            <!-- Promotions/Pricing/Qty -->
            <tile-shop-block-package
                v-if="(!isStaggeredAssemblyPackage && hasPackagePrice)"
                :query="query"
                :tile-position="tileIndex"
                :product-set="wheelPackage"
                :isWheelPackage="true"
                class="col-5 tile__promotions-pricing-wrapper"
            >
            </tile-shop-block-package>

            <!-- TEMP: CALL TO ACTION until shop block is added-->
            <div v-if="(isStaggeredAssemblyPackage || !hasPackagePrice)" class="col-5 tile__cta">
                <div class="col">
                    <p class="call-844-350-6505-or"><strong>Contact your Service Consultant or Chat below</strong> to speak with a
                    concierge regarding pricing and details.</p>
                    <p class="concierge-available">*Concierge available between 8:00 a.m. and 5:00 p.m. (EST).</p>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import prettyLgNum from '@/filters/pretty-lg-numbers/index.js';
import rating from '@/components/TileRating.vue';
import TileShopBlockPackage from '@/components/TileShopBlockPackage.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { contains, extend, map } from 'underscore';
import { fullTireMarkDescription } from '@/filters/oem-tire-mark';
import { structuredProductQuery } from '@/utils/product-query-builder';
import packagePrices from '../store/data/wheel-package-prices';

export default {
	name: 'ProductTilePackage',

	components: {
		rating,
		TileShopBlockPackage
	},

	props: {
		wheelPackage: {
			type: Object,
			required: true
		},
		query: {
			type: Object,
			required: true
		},
		tileIndex: {
			type: Number,
			required: true,
			default: 0
		}
	},

	filters: {
		fullTireMark(tire) {
			return fullTireMarkDescription(tire);
		}
	},
	data() {
		return {
			showImageButton: true,
			imageLoaded: true,
			specs: []
		};
	},
	methods: {
		...mapActions('products', ['addProductSetToCompare', 'removeProductSetFromCompare', 'setSingleProduct']),
		hideImageButton(event) {
			this.showImageButton = false;
			event.target.src = '/content/drsg/b_iso/default.jpg';
			this.imageLoaded = false;
		},
		goToPackageDetail() {
			this.setSingleProduct({
				query: structuredProductQuery(this.query),
				id: this.wheelPackage.productId,
				cid: this.query.cid,
				dealer: this.selected
			}).then(() => {
				this.$router.push({
					path: '/app/details',
					query: extend(this.query, {
						id: this.wheelPackage.productId,
						localBest: ''
					})
				});
			});
		}
	},
	computed: {
		...mapGetters('initial', ['powerReviews']),
		...mapState('products', ['maxToCompare', 'productSetsToCompare']),
		...mapState('dealer', ['selected']),
		detailsURL() {
			// return detailsPageURL(this.query, this.productSet);
			const baseURL = window.location.origin;
			const detailsPath = '/app/details?';
			const productId = `id=${this.wheelPackage.productId}`;
			let detailsQuery = '';

			for (const key in this.query) {
				if (key.toLowerCase() !== 'id') {
					detailsQuery += `${key}=${this.query[key]}&`;
				}
			}

			detailsQuery = detailsQuery.concat(productId);

			const url = `${baseURL}${detailsPath}${detailsQuery}&wpk`;

			return url;
		},
		hasFlags() {
			return this.wheelPackage.localBestSeller || this.wheelPackage.factoryInstalled || this.wheelPackage.oemTireMarkDescription;
		},
		hasWarranty() {
			return Number(this.wheelPackage.warrantyMilesNumeric)
				? `${prettyLgNum(this.wheelPackage.warrantyMilesNumeric)} ${this.$t('message.warrantyMiles')}`
				: '';
		},
		isFuelEfficient() {
			return this.wheelPackage.ecoTire ? this.$t('message.fuelEfficient') : '';
		},
		isRunFlat() {
			return this.wheelPackage.isRunflat ? this.$t('message.runFlat') : '';
		},
		isSelectedToCompare() {
			return contains(this.tireIds, this.wheelPackage.productId);
		},
		productImg() {
			return this.wheelPackage.imageId ? `/content/drsg/a_side/${this.wheelPackage.imageId}_a.jpg` : `/content/drsg/b_iso/default.jpg`;
		},
		showRoadHazard() {
			return this.wheelPackage.showRoadHazard ? this.$t('message.roadHazard') : '';
		},
		tireIds() {
			return map(this.productSetsToCompare, function (selectedProductSet) {
				return selectedProductSet.products[0].productId;
			});
		},
		isStaggeredAssemblyPackage() {
			// only SKU's ending in FT or RT
			const skuEndingFtOrRt = new RegExp('(ft|rt)$', 'gi');

			return skuEndingFtOrRt.test(this.wheelPackage.sku);
		},
		hasPackagePrice() {
			return packagePrices[this.wheelPackage.sku];
		}
	},
	created() {
		const wpk = this.wheelPackage;

		this.wheelPackage = wpk;

		let specs = [
			this.wheelPackage.season,
			this.isRunFlat,
			this.hasWarranty,
			this.showRoadHazard,
			this.isFuelEfficient,
			`${this.wheelPackage.size} | ${this.wheelPackage.serviceDescription}`
		];

		this.specs = specs;
	}
};
</script>
