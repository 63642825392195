<!-- eslint-disable no-undefined -->
<!-- eslint-disable complexity -->
<template>
    <tr>
        <th class="order-totals__cell">{{ $t("message.taxes") }}*</th>
        <td class="order-totals__cell order-totals__cell--right">{{$n(tax, 'currency') }}</td>

    </tr>
</template>

<script>
// Accepts the products that are in the cart,
// Gets the taxes for them
// Sets it to a thing so the cart object can grab it.
import { mapActions, mapGetters, mapState } from 'vuex';
import { extend, isEmpty } from 'underscore';
import axios from 'axios';
import * as currency from '@/utils/currency';

export default {
	props: {
		product: {
			type: Object
		},
		service: {
			type: Array
		},
		quantity: {
			type: Array
		},
		isWheelPackage: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			tax: 0
		};
	},
	methods: {
		...mapActions('cart/order', ['updateProductPaymentObject']),
		...mapGetters('cart/order', ['isMobileInstallation', 'mobileInstallationZip']),
		...mapState('cart', ['setTiresInCart'])
	},
	// eslint-disable-next-line complexity
	beforeMount: async function() {
		let product = this.product;
		const qty = isEmpty(this.qtySelected) ? this.defaultQty : this.qtySelected;

		product.products.forEach( (p, i) => {
			p.qty = qty[i];
		});

		this.setTiresInCart(this.product);

		let services = this.service;
		let subTotalAmount = services.reduce(
			(accu, s) => currency.addCurrency(accu, s.price),
			Number(this.isWheelPackage ? currency.multiplyCurrency(this.packagePriceEach, this.query.qty).toFixed(2) : this.product.pricing.installPriceAfterSavings)
		);

		const grandTotalAmount = subTotalAmount;


		try {
			const productPayload = {
				products: this.product.products.map(prod => {
					return {
						pricing: prod.pricing,
						qty: prod.qty,
						programCategory: prod.programCategory
					};
				})
			};
			let taxObj = {};

			taxObj.subTotalAmount = subTotalAmount.toFixed(2);
			taxObj.grandTotalAmount = taxObj.subTotalAmount;

			// eslint-disable-next-line no-undefined
			if (typeof taxObj.subscriptionID === undefined ) {
				taxObj.subscriptionID = '';
			}
			// eslint-disable-next-line no-undefined
			if (typeof taxObj.type === undefined) {
				taxObj.type = '';
			}

			// set initial pretax details as a fallback to failed tax requests
			this.updateProductPaymentObject(taxObj);

			let taxObj_req = await axios.post('/service/taxrequest', {product: productPayload,
				services,
				dealer: this.selected});

			taxObj = taxObj_req.data;

			// set finial tax details
			taxObj.subTotalAmount = subTotalAmount.toFixed(2);
			taxObj.grandTotalAmount = currency.addCurrency(taxObj.supplementalChargesAmount, subTotalAmount).toFixed(2);
			this.updateProductPaymentObject(taxObj);
			this.tax = taxObj.supplementalChargesAmount;
			this.$emit('taxesDone', taxObj);

		} catch (error) {
			// log tax request errors
			axios.get('/service/errorlog', {
				params: {
					message: 'error on checkout page - taxrequest',
					error: error.message,
					services: services,
					product: this.product,
					query: this.query

				}
			});
			// Here's the default object. It has zero taxes, with the pre-tax total filled out.
			// If taxes errors, that's okay, taxes are estimated, this will show the pre-tax price
			let taxObj = {
				supplementalChargesAmount: 0,
				subTotalAmount,
				grandTotalAmount,
				taxRateParts: 0,
				taxRateLabor: 0,
				taxes: {
					state: {
						description: '',
						percentage: 0,
						amount: 0
					},
					city: {
						description: '',
						percentage: 0,
						amount: 0
					},
					county: {
						description: '',
						percentage: 0,
						amount: 0
					}
				}
			};


			this.updateProductPaymentObject(taxObj);
			this.tax = taxObj.supplementalChargesAmount;
			this.$emit('taxesDone', taxObj);

		}


	},
	computed: extend({},
		mapState('products', ['qtySelected', 'defaultQty']),
		mapState('dealer', ['selected']),
		mapGetters('cart/order', ['orderPayment'])
	),
	watch: {
		orderPayment(paymentObj) {
			this.tax = paymentObj.supplementalChargesAmount;
			this.$emit('taxesDone', paymentObj);
		}
	}
};

</script>
