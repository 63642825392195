import axios from 'axios';

// Appointments
export const getAppointmentTime = ({ commit }) => axios
	.get('/service/appt')
	.then(({ data }) => {
		commit('selectedTime', data.time);
		commit('selectedTimeDealer', data.dealerTime);
	}).catch(err => {
		commit('selectedTime', '');
		commit('selectedTimeDealer', '');
		throw new Error(err);
	});

export const setAppointmentTime = ({ commit }, formattedDates) => {

	return axios.post('/service/appt', formattedDates)
		.then(() => {
			commit('selectedTime', formattedDates.apptDateTime);
			commit('selectedTimeDealer', formattedDates.apptDateTimeDealer);
		})
		.catch(err => {
			commit('selectedTime', '');
			commit('selectedTimeDealer', '');
			throw new Error(err);
		});
};

export const createAppointment = ({ commit }, { consumer, amenities, vehicle, products, isSearchBySize, blockDurationMinutes }) => {// eslint-disable-line max-len
	const mappedProducts = products.map(p => {
		return { itemId: p.itemId,
			qty: p.qty};
	});

	return axios.post('/service/createAppt', {
		amenities,
		consumer,
		vehicle,
		products: mappedProducts,
		isSearchBySize,
		blockDurationMinutes
	})
		.then(({ data }) => {
			const appointment = {
				apptID: data.appointmentID,
				apptProvider: data.provider,
				blockStartTime: data.blockStartTime,
				requestedDate: data.requestedDate,
				blockDurationMinutes: data.blockDurationMinutes,
				status: 1
			};

			commit('cart/order/setOrder', { appointment }, { root: true });
		})
		.catch((err) => {
			commit('cart/order/setOrder', {}, { root: true });
			throw new Error(err);
		});
};
