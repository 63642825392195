const config = require('../../config/dealer-config');

module.exports = requestHost => { // eslint-disable-line
	const themes = Object.keys(config);
	let matchingTheme = '';

	if (process.env.COUNTRY && process.env.COUNTRY.toUpperCase() === 'CA') {
		const canadaThemes = ['ioc', 'noc'];

		canadaThemes.forEach(theme => { //eslint-disable-line
			if (requestHost && requestHost.includes(config[theme].hostName) && !matchingTheme) {
				matchingTheme = config[theme];
			}
		});
		if (matchingTheme) {
			return matchingTheme;
		}

	}

	themes.forEach(theme => { //eslint-disable-line
		if (requestHost && requestHost.includes(config[theme].hostName) && !matchingTheme) {
			matchingTheme = config[theme];
		}
	});
	if (matchingTheme) {
		return matchingTheme;
	}

	return config['ddr'];

};
