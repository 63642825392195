<template>
    <div class="fs-unmask">
        <DealerHeader />
        <DealerContact />
        <LocaleSwitcher v-if="multiLanguageSupport" darkMode />

        <Tabs v-if="!isFetching" @activated="handleTabActivated">
            <Tab
                name="tabpanel0"
                title="message.byVehicle"
                dataCy="shopByVehicleTab"
                aria-selected="true"
                aria-labelledby="tab-0"
            >
                <ShopByVehicle
                    :query="query"
                    :target="target"
                    data-cy="shopByVehicle"
                />
            </Tab>
            <Tab
                v-if="hasSearchBySize"
                name="tabpanel1"
                title="message.bySize"
                dataCy="shopBySizeTab"
                aria-selected="false"
                aria-labelledby="tab-1"
            >
                <ShopByTireSize
                    :query="query"
                    :target="target"
                    tabindex="-1"
                    data-cy="shopBySize"
                />
            </Tab>
        </Tabs>

        <DealerFooter />
        <CookieConsent />
        <DialogModal
            :isOpen="isModalOpen"
            @close="closeTheModal"
            :ariaLabel="$t('message.ieSupportHeader')"
        >
            <h4 slot="header">{{ $t('message.ieSupportHeader') }}</h4>
            <p>{{ $t('message.ieSupportMessage') }}</p>
        </DialogModal>
    </div>
</template>

<script>

import CookieConsent from '@/components/CookieConsent.vue';
import ShopByVehicle from '@/components/ShopByVehicle.vue';
import ShopByTireSize from '@/components/ShopByTireSize.vue';
import DealerHeader from '@/components/DealerHeader.vue';
import DealerFooter from '@/components/DealerFooter.vue';
import DealerContact from '@/components/DealerContact.vue';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import Tabs from '@/components/BootstrapTabGroup.vue';
import Tab from '@/components/BootstrapTabPane.vue';
import { mapActions, mapState, mapMutations } from 'vuex';
import DialogModal from '@/components/DialogModal';
import { RudderstackSearchMixin } from '@/rudderstack/track/mixins/search';
import themeFinder from '../../plugins/theme-finder';
import { RudderstackChatMixin } from '@/rudderstack/track/mixins/chat';

export default {
	title: 'Home',

	props: {
		query: {
			type: Object,
			required: true
		}
	},

	mixins: [RudderstackSearchMixin, RudderstackChatMixin],

	components: {
		CookieConsent,
		ShopByVehicle,
		ShopByTireSize,
		Tabs,
		Tab,
		DialogModal,
		DealerHeader,
		DealerFooter,
		DealerContact,
		LocaleSwitcher
	},

	data() {
		return {
			isDemo: true,
			isModalOpen: false,
			isFetching: true
		};
	},


	computed: {
		...mapState('content', ['spotlight', 'trivia', 'advertisement']),
		...mapState('search', ['dealership_id']),
		...mapState('initial', ['hasSearchBySize', 'title']),

		target() {
			if (this.inIframe()) {
				return '_blank';
			}

			return '_self';
		},
		multiLanguageSupport() {
			const localeMount = document.getElementById('language');

			return localeMount;
		},
		newPageTitle() {
			// eslint-disable-next-line prefer-template
			const page = this.$t('message.homepage') + ' | ' + this.title;

			return page;
		}
	},

	methods: {
		...mapActions('content', ['fetchDocs']),
		...mapActions('dealer', ['select']),
		...mapMutations('consumerInfo', ['setUserLanguage']),
		...mapMutations('search', ['setSearchType']),
		// determing if we're in an iframe. Assume yes if we hit an error
		inIframe() {
			try {
				return window.self !== window.top;
			} catch (e) {
				return true;
			}
		},
		closeTheModal() {
			this.isModalOpen = false;
		},
		isIE() {
			const ua = window.navigator.userAgent;
			const msie = ua.indexOf('MSIE ');
			const trident = ua.indexOf('Trident/');

			if (msie > 0 || trident > 0) {
				this.isModalOpen = true;

				return true;
			}

			return false;
		},
		handleTabActivated(index) {
			const bySizeIndex = 1;

			if (index === bySizeIndex) {
				this.trackSearchStarted({ 'search_type': 'Size' });
				this.setSearchType('Size');
			} else {
				this.trackSearchStarted({ 'search_type': 'Vehicle' });
				this.setSearchType('Vehicle');
			}
		}
	},

	watch: {
		spotlight(data) {
			const el = document.getElementById('spotlight');

			if (el && data) {
				el.innerHTML = data;
			}
		},
		trivia(data) {
			const el = document.getElementById('trivia');

			if (el && data) {
				el.innerHTML = data;
			}
		},
		advertisement(data) {
			const el = document.getElementById('advertisement');

			if (el && data) {
				el.innerHTML = data;
			}
		}
	},

	mounted() {
		document.title = this.newPageTitle;

		this.isIE();
	},


	async created() {
		const { uid } = this.query;
		const storedLocale = window.localStorage.getItem('userLocale');
		const { defaultDealer } = themeFinder(window.location.hostname);


		if (storedLocale) {
			this.$i18n.locale = storedLocale;
			this.setUserLanguage(this.$i18n.locale);
		}

		await this.select({ uid: uid || defaultDealer});
		this.isFetching = false;

		this.$rudderstack.page(this.$route.meta.pageCategory, this.$route.meta.pageCategory, {
			...this.$route.query,
			'dealership_id': this.dealership_id
		});

		this.fetchDocs();
	}
};
</script>
